
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {collection, addDoc, getFirestore} from 'firebase/firestore'; 
import '../css/AddExpenses.css'
import data from '../fields.json'
import { useState } from 'react'
import firebaseConfig from './firebaseConfig';

const AddExpCount = props => {
    //Подключаемся к FireBase
    const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    const db = getFirestore(app);

    const [addExp, setAddExp] = useState(data.expenses)

    //Функция изменения значения
    const handleChange = (event) => {
        setAddExp((prevState) => ({
            ...prevState,
            id: String(Date.now()).slice(-6)
          }));
        const { name, value } = event.target;
        setAddExp((prevState) => ({
          ...prevState,
          [name]: value
        }));
        }

    //Функция очистки всех полей в объекте создания документа
    const handleClear = () => {
    const clearedUser = Object.keys(addExp).reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {});
    setAddExp(clearedUser);
  };
        
    const handleSave = async () => {
        try {
            // Создаем новый документ в коллекции "users" с данными пользователя
      // eslint-disable-next-line
            const docRef = await addDoc(collection(db, props.user.expenses), {
                addExp
            });
            // Очищаем поля ввода после сохранения данных
            handleClear()
            console.log(addExp)
          } catch (error) {
            console.error('Ошибка при добавлении документа:', error);
          }
    }
    return (
        <div>
            <div className="contAddExp">
                <h3 style={{margin:"0"}}>Добавить расходы</h3>

            <div className="contInputExp">
                    <p>Дата расхода:</p>
                    <input 
                                  name="date"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={addExp.date}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInputExp">
                    <p>Сумма:</p>
                    <input 
                                  name="amount"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addExp.amount}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInputExp">
                    <p>Комментарий:</p>
                    <input 
                                  name="comment"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addExp.comment}
                                  onChange={handleChange}
                        />
            </div>
            
                    <button style={{cursor:"pointer"}} className="redButtonExp" onClick={handleSave}>Сохранить расход</button>
            </div>
        </div>
    )
}

export default AddExpCount