import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/ListBikes.css'
import '../css/Search.css'

const moment = require('moment');

const ListAllBooking = props => {
    const [allBook, setAllBook] = useState(null)
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredBooks, setFilteredBooks] = useState(allBook)
    
    const navigate = useNavigate()
 
    useEffect(() => {
        if (props.allBooking) {
            const updatedBookings = props.allBooking.map(booking => {
                // Находим соответствующий объект в массиве props.allBike по id
                const matchingBike = props.allBike.find(bike => bike.id === booking.addBooking.id);
                const nameBike = matchingBike ? `${matchingBike.addBike.brand} ${matchingBike.addBike.model}`:"-"
                const idBike = matchingBike ? matchingBike.addBike.id:""
                // Возвращаем новый объект с добавленными свойствами, сохраняя остальные значения
                return {
                    ...booking,
                    addBooking: {
                        ...booking.addBooking,
                        link: matchingBike ? idBike : null,
                        nameBike: matchingBike ? nameBike : null
                    }
                };
            });

            const sortedUpSort = [...updatedBookings].sort((a, b) => {
                const dateA = a.addBooking.dateStart ? new Date(a.addBooking.dateStart).getTime() : new Date('9999-12-31').getTime();
                const dateB = b.addBooking.dateStart ? new Date(b.addBooking.dateStart).getTime() : new Date('9999-12-31').getTime();
                return dateB - dateA;
              });
            setAllBook(sortedUpSort)

        }
    }, [props]);

    useEffect(() => {
        // Фильтруем массив allBook по заданным полям
        if (allBook) {
        const updatedBooks = allBook.filter(book => 
            book.addBooking.fio.toLowerCase().includes(searchTerm.toLowerCase()) ||
            book.addBooking.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
            book.addBooking.comment.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredBooks(updatedBooks);
        }
    }, [searchTerm, allBook]); // Обновляем фильтрацию при изменении searchTerm или allBook

    const handleChangeName = (event) => {
        setSearchTerm(event.target.value);
      };
    
    return (
      <div className="contListBikes">
        <h2 style={{margin:0, marginBottom:"20px"}}>Все аренды</h2>
        <div className="inpCont">
            <input className='inputInp'
                 placeholder="Данные: ФИО, номер телефона, комментарий"
                type="text" value={searchTerm} onChange={handleChangeName}/>
         </div>

        {allBook ?
      <table className='table' style={{tableLayout:"fixed"}}>
          <colgroup>
          <col style={{width:"50px"}}/> {/* Первый столбец */}
          <col /> {/* Второй столбец */}
          <col /> {/* Третий столбец */}
          <col style={{ width: "220px" }} /> {/* Четвертый столбец фиксированной ширины */}
          <col /> {/* Пятый столбец */}
          <col /> {/* Шестой столбец */}
          <col /> {/* Седьмой столбец */}
          <col /> {/* Восьмой столбец */}
        </colgroup>
        <thead >
          <tr >
              <th>#</th>
              <th style={{cursor:"pointer"}}>Фио Клиента</th> 
              <th style={{cursor:"pointer"}} >Номер телефона</th>
              <th >Комментарий</th> 
              <th >Место проживания</th>
              <th>Дата начала аренды</th>
              <th >Дата окончания аренды</th>
              <th >Байк</th>
              {/* <th>Статус</th> */}
              
          </tr>
        </thead>
        <tbody>
          {filteredBooks && filteredBooks.map((item, ind)=> 
            <tr key={ind} onClick={()=>navigate(`/redAdd${item.addBooking.link}`)} style={{cursor:"pointer"}} >
                <td onClick={()=>console.log(item.id)}>{ind+1}</td>
                <td>{item.addBooking.fio}</td>
                <td>{item.addBooking.phone}</td>
                <td >{item.addBooking.comment}</td>
                <td>{item.addBooking.place}</td>
                <td>{moment(item.addBooking.dateStart).format("DD-MM-YYYY HH:mm")}</td>
                <td>{moment(item.addBooking.dateEnd).format("DD-MM-YYYY HH:mm")}</td>
                <td>{item.addBooking.nameBike}</td>
            </tr>
          )}
        </tbody>
      </table>      
      :<div onClick={()=>console.log(props.allBooking)}>Loading...</div>
}
      </div>
    
    );
  }

export {ListAllBooking}