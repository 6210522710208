import { Link, useNavigate } from 'react-router-dom';
import '../css/Home.css'


const Header = props => {

    const visMenu = () => {
        props.menu()
    }

    const isKok = props.isKok

    const navigate = useNavigate()
    return (
        <nav className="cont" >
            <div className='contHome'>
                <div onClick={()=>{navigate(-1)}} className="addBike" style={{height:"15px", paddingLeft:"10px", paddingRight:"10px", paddingTop:"3px"}} >Back</div>
                <Link to='/' className="addBike" style={{width:"20px", height:"20px", borderRadius:"50%"}}>YB</Link>
                {!isKok && <Link to='/allbooking' className="addBike" style={{backgroundColor:"#c1d6c7"}}>Клиенты</Link>}
                {!isKok &&  <Link to='/reservations' className="addBike" >Бронирования</Link>}
                {!isKok &&  <Link to='/allcount' className="addBike" >Сводка отчеты</Link>}
                {!isKok &&  <Link to='/addbike' className="addBike" >Добавить мопед</Link>}
                <Link to='/price' className="addBike" >Prices</Link>
                <button className="addBike" style={{width:"30px", height:"30px", borderRadius:"50%"}} onClick={visMenu}>+</button>
                {/* <button className="addBike" onClick={()=>navigate('/allcount')}>Сводка отчеты</button>
                <button className="addBike" onClick={()=>navigate('/addbike')}>Добавить мопед</button>
                <button className="addBike" onClick={()=>navigate('/price')}>Цены</button> */}
            </div>
        </nav>
    )
}

export {Header}