import '../css/AllCount.css'
import AddReserv from './AddReserv';
import RedReserv from './RedReserv';
import { useEffect, useState } from 'react'

const moment = require('moment');

const Reservations = props => {

    const [allReserv, setAllReserv] = useState(null)

    const [thisReserv, setThisReserv] = useState(null)

    const [currDate, setCurrDate] = useState(1)

    const [countStr, setCountStr] = useState(5)


    //Показываем все/часть бронирований
    const showAllReserv = () => {
        setCountStr(100000)
    }

    const showReserv = reserv => {
        setThisReserv(reserv)
    }
    useEffect(()=>{
    const currentDate = new Date();
    
    setCurrDate(currentDate.setHours(0, 0, 0, 0))
    if (props.allReservations) {
    const sortedUpSort = [...props.allReservations].sort((a, b) => {
        const dateA = new Date(a.addReservation.date);
        const dateB = new Date(b.addReservation.date);
        return  dateB - dateA;
    })
    setAllReserv(sortedUpSort)
}
    },[props])

    return (
        <div>
        <div className="contTable1" style={{width:"87%",minWidth:"900px"}}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
             <h2 style={{margin:"0", marginBottom:"10px", marginTop:"10px"}}>Бронирования</h2>
             {countStr<6 && <div className="btnAllRes" onClick={showAllReserv}>Показать все</div>}
        </div>
        <table className='tableCount'>
        <thead >
          <tr>
              <th onClick={()=> console.log(allReserv)}>Дата начала аренды</th> 
              <th>Срок аренды</th> 
              <th>Какой байк</th> 
              <th>Место доставки</th> 
              <th>Контактное лицо</th> 
              <th>Условия аренды</th> 
          </tr>
        </thead>
        <tbody>
        {allReserv && allReserv.slice(0, countStr).map((group, id) => (
            <tr key={id} onClick={()=> showReserv(group)} style={{cursor:"pointer", backgroundColor: new Date(group.addReservation.date)>currDate ? "#aeff9e": "#e0e0e0" }}>
            <td>{moment(new Date(group.addReservation.date)).format('DD MMMM YYYY')}</td>
            <td>{group.addReservation.term}</td>
            <td>{group.addReservation.bike}</td>
            <td>{group.addReservation.location}</td>
            <td>{group.addReservation.contact}</td>
            <td>{group.addReservation.price}</td>
            </tr>
        ))}
        </tbody>
      </table>
      </div>
            <RedReserv reserv={thisReserv} user={props.user}/>
            <AddReserv allReservations={props.allReservations} user={props.user}/>
        </div>
    )
}

export {Reservations}