import { initializeApp } from 'firebase/app';
import {collection, addDoc, getFirestore} from 'firebase/firestore'; 
import '../css/AddBike.css'
import data from '../fields.json'
import { useState } from 'react'
import firebaseConfig from './firebaseConfig'
// import Block from './Block'

const AddPrice = props => {
    //Подключаемся к FireBase
    const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    const db = getFirestore(app);

    const [addReservation, setAddReservation] = useState(data.bikesprice)

    //Функция изменения значения
    const handleChange = (event) => {
        // console.log(props.allReservations)
        const { name, value } = event.target;
            setAddReservation((prevState) => ({
          ...prevState,
          [name]: value
        })
        )};
        

    //Функция очистки всех полей в объекте создания документа
    const handleClear = () => {
    const clearedUser = Object.keys(addReservation).reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {});
    setAddReservation(clearedUser);
  };
        
    const handleSave = async () => {
        try {
      // eslint-disable-next-line
            const docRef = await addDoc(collection(db, props.user.bikesprice), {
                addReservation
            });
            // Очищаем поля ввода после сохранения данных
            handleClear()
            window.location.reload()
          } catch (error) {
            console.error('Ошибка при добавлении документа:', error);
          }
    }
    return (
        <div className="contRedAdd" style={{paddingTop:"4%", width:"87%", marginLeft:"7%", minWidth:"945px", margin:"auto", boxSizing:"border-box", marginTop:"40px", marginBottom:"40px"}}>
            <div >
                <h3 style={{margin:"0", marginBottom:"10px"}}>Добавить расценки</h3>
                <div className="contInput">
                    <p>Модель:</p>
                    <input 
                                  name="model"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.model}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Цена посуточно:</p>
                    <input 
                                  name="priceday"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.priceday}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Цена за неделю:</p>
                    <input 
                                  name="priceweek"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.priceweek}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Цена две недели:</p>
                    <input 
                                  name="pricetwoweek"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.pricetwoweek}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Цена месяц:</p>
                    <input 
                                  name="pricemonth"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.pricemonth}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Депозит:</p>
                    <input 
                                  name="deposit"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.deposit}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    
                    <div>
                        {/* <button>Распечатать договор</button> */}
                        <button className="redButton" onClick={handleSave}>Сохранить</button>
                    </div>
                </div>
        </div>
    )
}

export default AddPrice