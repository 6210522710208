const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "crm-rent-6beb0.firebaseapp.com",
  projectId: "crm-rent-6beb0",
  storageBucket: "crm-rent-6beb0.appspot.com",
  messagingSenderId: "766545790591",
  appId: "1:766545790591:web:d2e15132452f149322614a",
  measurementId: "G-36T2TS7620"
  };

export default firebaseConfig