import { useEffect, useState } from 'react'
import '../css/CountIcom.css'

const Total = props => {

    //Общие расходы на покупку байков
    const [expBikes, setExpBikes] = useState(0)

    //Общие расходы
    const [totalExp, setTotalExp] = useState(0)

    //Общие доходы
    const [allIcoms, setAllicoms] = useState(0)

    useEffect(()=>{
        if (props.allBike && props.allExpenses && props.allBooking){
        const totalSum = props.allBooking.reduce((sum, total) => sum + parseInt(total.addBooking.totalPrice), 0);
        setAllicoms(totalSum)

        const allExp = props.allExpenses.reduce((sum, total) => sum + parseInt(total.addExp.amount), 0)
        setTotalExp(allExp)

        const allBuyBike = props.allBike.reduce((sum, total) => {
            const buyPrice = parseInt(total.addBike.buyPrice) || 0;
            const sellPrice = parseInt(total.addBike.sellPrice) || 0;
            return sum + buyPrice - (total.addBike.sellPrice ? sellPrice : 0);
        }, 0);
        setExpBikes(allBuyBike)
        }
    },[props])

    return (
        <div className='totalCont'>
            <h3 style={{margin:"0", marginBottom:"15px"}}>Сводка за весь период</h3>
            <div className="styleIcom">
            <div className='contTotalIc' style={{backgroundColor:"#ebc28d"}}>
                <p>Всего расходов:</p>
                <p>{(expBikes + totalExp).toLocaleString()} THB</p>
            </div>
            <div className='contTotalIc' style={{backgroundColor:"#b9eb8d"}}>
                <p>Всего доходов:</p>
                <p>{allIcoms.toLocaleString()} THB</p>
            </div>
            </div>

            <div className="styleIcom">
            <div className='contTotalIc' style={{backgroundColor:"#ebac8d"}}>
                <p>Расходов на покупку байков:</p>
                <p>{expBikes.toLocaleString()} THB</p>
            </div>
            <div className='contTotalIc' style={{backgroundColor:"#a9d6ba"}}>
                <p>Доход в процентах от всех затрат:</p>
                <p>{(allIcoms/((expBikes+totalExp)/100)).toFixed(2)} %</p>
            </div>
            </div>

            <div className="styleIcom">
            <div className='contTotalIc' style={{backgroundColor:"#c7bfb1"}}>
                <p>Расходы на обслуживание:</p>
                <p>{totalExp.toLocaleString()} THB</p>
            </div>
            <div className='contTotalIc' style={{backgroundColor:"#a9d6ba"}}>
                <p>Доход в процентах от покупки байков:</p>
                <p>{(allIcoms/((expBikes)/100)).toFixed(2)} %</p>
            </div>
            </div>

            <div className="styleIcom">
            <div className='contTotalIc' style={{backgroundColor:"#beb1c7"}}>
                <p>Доходы минус срочные расходы:</p>
                <p>{(allIcoms-totalExp).toLocaleString()} THB</p>
            </div>
            <div className='contTotalIc' style={{backgroundColor:"#deadad"}}>
                <p>Доходы минус все расходы:</p>
                <p>{(allIcoms-(totalExp+expBikes)).toLocaleString()} THB</p>
            </div>
            </div>

        </div>
    )
}

export default Total