import { initializeApp } from 'firebase/app';
import {collection, addDoc, getFirestore} from 'firebase/firestore'; 
import '../css/AddBike.css'
import data from '../fields.json'
import { useState } from 'react'
import firebaseConfig from './firebaseConfig'
// import Block from './Block'

const AddReserv = props => {
    //Подключаемся к FireBase
    const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    const db = getFirestore(app);

    const [addReservation, setAddReservation] = useState(data.reserv)

    //Функция изменения значения
    const handleChange = (event) => {
        // console.log(props.allReservations)
        const { name, value } = event.target;
            setAddReservation((prevState) => ({
          ...prevState,
          [name]: value
        })
        )};
        

    //Функция очистки всех полей в объекте создания документа
    const handleClear = () => {
    const clearedUser = Object.keys(addReservation).reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {});
    setAddReservation(clearedUser);
  };
        
    const handleSave = async () => {
        try {
      // eslint-disable-next-line
            const docRef = await addDoc(collection(db, props.user.reservations), {
                addReservation
            });
            // Очищаем поля ввода после сохранения данных
            handleClear()
            window.location.reload()
          } catch (error) {
            console.error('Ошибка при добавлении документа:', error);
          }
    }
    return (
        <div className="contRedAdd" style={{width:"87%", minWidth:"900px",margin:"auto", marginTop:"40px", padding:"3%", boxSizing:"border-box"}}>
            <div >
                <h3 style={{margin:"0", marginBottom:"10px"}}>Добавить бронирование</h3>
                <div className="contInput">
                    <p>Дата начала аренды:</p>
                    <input 
                                  name="date"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.date}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Срок</p>
                    <input 
                                  name="term"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.term}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Какой байк:</p>
                    <input 
                                  name="bike"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.bike}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Место аренды:</p>
                    <input 
                                  name="location"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.location}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Контакты:</p>
                    <input 
                                  name="contact"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.contact}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Условия аренды: </p>
                        <input 
                                  name="price"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addReservation.price}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    
                    <div>
                        {/* <button>Распечатать договор</button> */}
                        <button className="redButton" onClick={handleSave}>Сохранить</button>
                    </div>
                </div>
        </div>
    )
}

export default AddReserv