import "../css/Modal.css"

const Modal = () => {
    return (
        <div className="contModal">
            <div className="contText">
                <p className="text">Successfully saved</p>
            </div>
        </div>
    )
}

export default Modal