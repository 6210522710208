import '../css/AllCount.css'
import { useEffect, useState } from 'react';
import AddExpCount from './AddExpCount'
import RedExpenses from './RedExpenses';

const moment = require('moment');
require('moment/locale/ru');

const AllExpenses = props => {

    const [expenses, setExpenses] = useState(null)
    //Для группировки по месяцам
    const [groupedExpenses, setGroupedExpenses] = useState([]);

    //Отображение расходов по месяцу
    const [expMonth, setExpMonth] = useState(null)
    
    //Отображение месяца расходов
    const [mon, setMon] = useState(null)

    //Для редактирования расходов
    const [exp, setExp] = useState(null)

//Все расходы по периоду
    const showExp = (exp) => {
      const sortedUpSort = [...exp.expenses].sort((a, b) => {
          const dateA = new Date(a.addExp.date);
          const dateB = new Date(b.addExp.date);
          return dateB - dateA
        });
        setExpMonth(sortedUpSort)
        setMon(exp)
    }
//Отображение окна редактирования
    const showRed = (item) => {
        setExp(item)
    }
//Показать окно добавления расходов
    const addExp = () => {
        setExp(null)
        setExpMonth(null)
    }

    useEffect(()=> {
        setExpenses(props.allExpenses)
    },[props])

    // useEffect(() => {
    //   console.log("EXPMONTH")
    //     if (expMonth) {
    //       const result = expMonth
    //       result.sort((a, b) => {
    //         const dateA = new Date(a.addexp.date);
    //         const dateB = new Date(a.addexp.date);
    //         return dateB.getTime() - dateA.getTime();
    //       });
    //       setExpMonth(result)
    //     }
    //   },[])

    useEffect(() => {
        if (expenses) {
          // Функция для группировки расходов по месяцам
          const groupExpensesByMonth = () => {
            const groupedByMonth = {};
      
            expenses.forEach((expense) => {
              const date = new Date(expense.addExp.date);
              const month = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
              // const month = `${date.getFullYear()}-${date.getMonth() + 1}`;
      
              if (!groupedByMonth[month]) {
                groupedByMonth[month] = [];
              }
              groupedByMonth[month].push(expense); // Сохраняем объект expense целиком
            });
      
            const result = Object.keys(groupedByMonth).map((month) => {
              const totalAmount = groupedByMonth[month].reduce((acc, expense) => acc + parseInt(expense.addExp.amount), 0);
              
              const date = new Date(month)
      
              return {
                month,
                //записываем все расходы
                expenses: groupedByMonth[month],
                totalAmount,
                date: date,
              };
            });
      //Сортировка по месяцам от новых к старым
            result.sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateB.getTime() - dateA.getTime();
            });
      
            // Устанавливаем сгруппированные данные в состояние
            setGroupedExpenses(result);
            // console.log(result)
          };
      
          groupExpensesByMonth();
        }
      }, [expenses]);
      

    return (
        <div>
        <div className="contCount">
        <div className="contTable1">
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
             <h3 style={{margin:"0", marginBottom:"10px"}}>Расходы по месяцам</h3>
             {expMonth && <button onClick={addExp} style={{width:"40px", height:"30px", cursor:"pointer", fontSize:"22px"}}>+</button>}
        </div>
        <table className='tableCount'>
        <thead >
          <tr >
              <th >Месяц</th> 
              <th>Всего расходов</th> 
              {/* <th>Всего приходов</th> 
              <th>Чистая прибыль</th>      */}
          </tr>
        </thead>
        <tbody>
        {groupedExpenses.map((group) => (
            <tr key={group.month} >
            <td onClick={()=>console.log(group)} >{moment(new Date(group.date)).format('MMMM YYYY')}</td>
            {/* <td>{new Date(group.date).toLocaleString('default', { month: 'long' })}</td> */}
            <td style={{cursor:"pointer"}} onClick={() => showExp(group)}>{group.totalAmount}</td>
                {/* <td>TEST</td>
                <td>TEST2</td> */}
            </tr>
        ))}
        </tbody>
      </table>
      </div>
      
      {expMonth ? 
            <div className="contTable2">
            <h3 style={{margin:"0", marginBottom:"10px"}}>Расходы за {new Date(mon.date).toLocaleString('default', { month: 'long' })}</h3>
            <table className='tableCount2'>
            <thead>
                <tr style={{backgroundColor:"#e88989"}}>
                    <th>Дата</th>
                    <th>Сумма</th>
                    <th>Комментарий</th>
                </tr>
            </thead>
            <tbody>
            {expMonth.map((item, ind) => (
                <tr onClick={()=> showRed(item)} key={ind} style={{cursor:"pointer"}}>
                    <td>{moment(item.addExp.date).format("DD-MM-YYYY")}</td>
                    <td>{item.addExp.amount}</td>
                    <td>{item.addExp.comment}</td>
                </tr>
            ))}
             </tbody>
             </table>

            {exp && <RedExpenses exp={exp} user={props.user}/>}    


            </div>:
            <div className="contTable2" style={{paddingTop:"15px"}}>
             <AddExpCount user={props.user}/>
            </div>
        }
            </div>
        </div>
    )
}

export default AllExpenses