import { useEffect, useState } from 'react'
import '../css/CountIcom.css'

const CountIcom = props => {

    const [sumTotal, setSumTotal] = useState(0)
    const [clearIcom, setClearIcom] = useState(0)
    const [percentIcom, setPercentIcom] = useState(0)
    const [expenses, setExpenses] = useState(0)
    const [manyMonth, setManyMonth] = useState(0)

    useEffect(()=>{
        if (props.allData){
        const totalSum = props.allData.reduce((sum, total) => sum + parseInt(total.addBooking.totalPrice), 0);
        setSumTotal(totalSum)

        const allExp = props.allExpenses.reduce((sum, total) => sum + parseInt(total.addExp.amount), 0)
        setExpenses(allExp)
        const price = parseInt(props.bike.buyPrice)

        const diff = totalSum - (price+allExp)
        setClearIcom(diff)

        const percent = (parseFloat(totalSum/((price+allExp)/100))).toFixed(2)
        setPercentIcom(percent)
        }
        const dateBuy = new Date(props.bike.dateBuy); // пример даты покупки
        const dateSell = new Date(props.bike.dateSell); // пример даты продажи
        
        const yearsDifference = dateSell.getFullYear() - dateBuy.getFullYear();
        const monthsDifference = dateSell.getMonth() - dateBuy.getMonth();
        
        const totalMonthsDifference = (yearsDifference * 12) + monthsDifference;
        setManyMonth(totalMonthsDifference)
    },[props])

    return (
        <div style={{marginBottom:"20px"}}>

            <div className="styleIcom">
            <div className='contTotalIc' >
                <p>Куплен за:</p>
                <p>{props.bike.buyPrice} THB</p>
            </div>
            <div className='contTotalIc' style={{backgroundColor:"#bbd6a9"}}>
                <p>Принес в процентах:</p>
                <p>{percentIcom} %</p>
            </div>
            </div>

            <div className="styleIcom">
            <div className='contTotalIc' style={{backgroundColor:"#a9d6ba"}}>
                <p>Расходов на обслуживание:</p>
                <p>{expenses} THB</p>
            </div>
            <div className='contTotalIc' style={{backgroundColor:"#a9d6ba"}}>
                <p>Всего принес:</p>
                <p>{sumTotal} THB</p>
            </div>
            </div>

            <div className="styleIcom">
            <div className='contTotalIc' style={{backgroundColor:"#d1cea7"}}>
                <p>Всего расходов:</p>
                <p>{expenses + parseInt(props.bike.buyPrice)} THB</p>
            </div>
            <div className='contTotalIc' style={clearIcom>0 ? {backgroundColor:"#62d446"}:{backgroundColor:"#d6a9a9"}}>
                <p>Доходы минус расходы:</p>
                <p>{clearIcom} THB</p>
            </div>
            </div>
        {props.bike.sellPrice  &&
            <h3>Расчеты после продажи</h3>
        }
        {props.bike.sellPrice &&
            <div className="styleIcom">
            <div className='contTotalIc' >
                <p>Продан за:</p>
                <p>{props.bike.sellPrice} THB</p>
            </div>
            <div className='contTotalIc' style={{backgroundColor:"#00ff4d"}}>
                <p>Чистый доход:</p>
                <p>{clearIcom+ parseInt(props.bike.sellPrice)} THB</p>
            </div>
            </div>
            }
        {props.bike.sellPrice &&
            <div className="styleIcom">
            <div className='contTotalIc' >
                <p>Срок использования:</p>
                <p>{manyMonth} месяцев</p>
            </div>
            <div className='contTotalIc' style={{backgroundColor:"#00ff4d"}}>
                <p>Принес в процентах:</p>
                <p>{((clearIcom+ parseInt(props.bike.sellPrice))/(parseInt(props.bike.buyPrice)/100)).toFixed(2)}</p>
            </div>
            </div>
            }
        </div>
    )
}

export default CountIcom