import React, { useState } from "react"
import {getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import '../css/EnterWindow.css'
import firebaseConfig from '../js/firebaseConfig'

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const EnterWindow = () => {

    const [emailReg, setEmailReg]= useState('')
    const [passReg, setPassReg]= useState('')

    const [errorEnter, setErrorEnter] = useState(null)


    const handleLogin = (event) => {
        event.preventDefault() //Событие не перезагружает страницу
        signInWithEmailAndPassword(auth, emailReg, passReg)
          .then((userCredential) => {
            
            // Вход успешно выполнен
            const user = userCredential.user;
            localStorage.setItem("uid", user.uid);
            localStorage.setItem("uid1", user.uid);
            setTimeout(function() {window.location.reload()},300)
            
            // if (user.uid === process.env.REACT_APP_UID_KEY2 || user.uid === process.env.REACT_APP_UID_KEY1) {
            //     localStorage.setItem("uid", user.uid);
            // } else {
            //     localStorage.setItem('user', JSON.stringify(user))
            //     localStorage.setItem("uid", user.uid);
            // }
          })

          .catch((error) => {
            // Обработка ошибок входа
            setErrorEnter(error.message);
          });
      };

    return (
        <>
        <form  className='contEnter' onSubmit={handleLogin} >
            <p style={{fontWeight:"bold", fontSize:"24px"}} className='textEnt1' >Авторизация</p>
            {errorEnter && <p style={{color:'red'}}>Неверные логин или пароль</p>}
            <div className='contInputEnt' >
                <p className='textLabelEnt'>Email</p>
                <input className='inpEnt' type='email' placeholder='name@example.com' minLength={7} maxLength={40} value={emailReg} onChange={event => setEmailReg(event.target.value)}></input>

                <p className='textLabelEnt'>Пароль</p>
                <input className='inpEnt' type='password' value={passReg} onChange={event => setPassReg(event.target.value)}></input>
                <button className='regButtonEnt' type='submit'>Войти</button>
            </div>
        </form>
        </>
    )
}
export {EnterWindow}