
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {collection, addDoc, getFirestore} from 'firebase/firestore'; 
import '../css/AddBike.css'
import data from '../fields.json'
import { useState} from 'react'
import firebaseConfig from './firebaseConfig';

// import user from '../users.json'

const AddBike = props => {
    //Подключаемся к FireBase
    const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    const db = getFirestore(app);

    const [addBike, setAddBike] = useState(data.rentBikes.inputs)

    //Функция изменения значения
    const handleChange = (event) => {
        setAddBike((prevState) => ({
            ...prevState,
            id: String(Date.now()).slice(-6)
          }));
        const { name, value } = event.target;
        setAddBike((prevState) => ({
          ...prevState,
          [name]: value
        }));
        }

    //Функция очистки всех полей в объекте создания документа
    const handleClear = () => {
    const clearedUser = Object.keys(addBike).reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {});
    setAddBike(clearedUser);
  };
        
    const handleSave = async () => {
        try {
            // Создаем новый документ в коллекции "users" с данными пользователя
      // eslint-disable-next-line
            const docRef = await addDoc(collection(db, props.user.bikes), {
                addBike
            });
            // Очищаем поля ввода после сохранения данных
            handleClear()
          } catch (error) {
            console.error('Ошибка при добавлении документа:', error);
          }
    }
    return (
        <div>
            <div className="contAddBike">
                <h2 style={{margin:"0", marginBottom:"10px"}} >Добавить Байк</h2>
            <div className="contInput">
                     <p>Марка:</p>
                    <input 
                                  name="brand"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.brand}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Модель:</p>
                    <input 
                                  name="model"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.model}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Год выпуска:</p>
                    <input 
                                  name="year"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.year}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Цвет: </p>
                        <input 
                                  name="color"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.color}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Цена покупки: </p>
                        <input 
                                  name="buyPrice"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.buyPrice}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Дата покупки:</p>
                    <input 
                                  name="dateBuy"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.dateBuy}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Рег. номер:</p>
                    <input 
                                  name="regNumber"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.regNumber}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>WIN:</p>
                    <input 
                                  name="WIN"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.WIN}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Пробег текущий:</p>
                    <input 
                                  name="mileageNow"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.mileageNow}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Пробег последнего ТО:</p>
                    <input 
                                  name="mileageLastTo"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.mileageLastTo}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Дата последнего ТО:</p>
                    <input 
                                  name="lastToDate"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.lastToDate}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Пробег для ТО:</p>
                    <input 
                                  name="tokm"
                                  placeholder='Сколько км нужно проехать для ТО'
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.tokm}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Страховка до:</p>
                    <input 
                                  name="insurance"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.insurance}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Комментарий:</p>
                    <input 
                                  name="comment"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.comment}
                                  onChange={handleChange}
                        />
            </div>
            <div className="contInput">
                    <p>Порядковый номер:</p>
                    <input 
                                  name="bikeNumber"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBike.bikeNumber}
                                  onChange={handleChange}
                        />
            </div>
                    <button className="redButton" onClick={handleSave}>Добавить байк</button>
            </div>
        </div>
    )
}

export {AddBike}