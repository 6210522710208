import '../css/Modal.css'

const ModalDel = props => {

    const deleteBike = () => {
        props.deleteDocument()
    }

    const cancelBike = () => {
       props.visDel()
    }


    return (
        <div className="contModal">
            <div className="contText">
                <p className="text">Точно удалить байк</p>
                <button className='btnDel' style={{color:"red", marginBottom:"20px", marginTop:"20px"}} onClick={deleteBike}>Да, удалить</button>
                <button className='btnDel' onClick={cancelBike}>Нет не удалять</button>
            </div>
        </div>
    )
}

export default ModalDel