import '../css/AllCount.css'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

const moment = require('moment');
const AllIcoms = props => {

const navigate = useNavigate()

const [allBooking, setAllBooking] = useState(null)

//Для группировки по месяцам
const [groupedIcomes, setGroupedIcomes] = useState([]);

//Отображение доходов по месяцу
const [icomMonth, setIcomMonth] = useState(null)

//Отображение месяца доходов
const [mon, setMon] = useState(null)

//Все доходы по периоду
const showIcom = (icom) => {
          //Сортировка по месяцам от новых к старым
    const result = icom.expenses
          result.sort((a, b) => {
            const dateA = new Date(a.addBooking.dateStart);
            const dateB = new Date(b.addBooking.dateStart);
            return dateB.getTime() - dateA.getTime();
          });
    setIcomMonth(icom.expenses)
    // console.log(icom.expenses)
    setMon(icom)

    // const rightBike = props.allBike.filter(item => item.id === icom.expenses.addBooking.id)
    // setBike(rightBike)
    // console.log(icom.expenses)

}

    useEffect(()=> {
        setAllBooking(props.allBooking)
    },[props])

    useEffect(() => {
        if (allBooking) {
          // Функция для группировки расходов по месяцам
          const groupExpensesByMonth = () => {
            const groupedByMonth = {};
      
            allBooking.forEach((expense) => {
              const date = new Date(expense.addBooking.dateStart);
              const month = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
      
              if (!groupedByMonth[month]) {
                groupedByMonth[month] = [];
              }
              
              groupedByMonth[month].push(expense); // Сохраняем объект expense целиком
            });
      
            const result = Object.keys(groupedByMonth).map((month) => {
              const totalAmount = groupedByMonth[month].reduce((acc, expense) => acc + parseInt(expense.addBooking.totalPrice), 0);
              const totalDelivery = groupedByMonth[month].reduce((acc, expense) => {
                const deliveryValue = parseInt(expense.addBooking.delivery);
                
                // Проверяем, что deliveryValue - это число и не равно NaN, и что не равно "-" или " "
                if (!isNaN(deliveryValue) && deliveryValue !== "-" && deliveryValue !== " ") {
                  return acc + deliveryValue;
                }
              
                return acc;
              }, 0);
              const date = new Date(month);
      
              return {
                month,
                //записываем все доходы
                expenses: groupedByMonth[month],
                totalAmount,
                totalDelivery,
                date: date,
              };
            });
      //Сортировка по месяцам от новых к старым
            result.sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateB.getTime() - dateA.getTime();
            });
      
            // Устанавливаем сгруппированные данные в состояние
            setGroupedIcomes(result);
          };
      
          groupExpensesByMonth();
        }
      }, [allBooking]);

    return (
        <div className="contCount" style={{marginBottom:"40px"}}>
        <div className="contTable1">
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
             <h3 style={{margin:"0", marginBottom:"10px"}}>Доходы по месяцам</h3>
        </div>
        <table className='tableCount'>
        <thead >
          <tr >
              <th>Месяц</th> 
              <th>Всего доходов</th> 
              <th>Доставка</th>
          </tr>
        </thead>
        <tbody>
        {groupedIcomes.map((group, id) => (
            <tr key={group.month} onClick={() => showIcom(group)} style={{cursor:"pointer"}}>
            <td onClick={()=>console.log(group)} >{moment(new Date(group.date)).format('MMMM YYYY')}</td>
            <td  >{group.totalAmount}</td>
            <td  >{group.totalDelivery}</td>
            </tr>
        ))}
        </tbody>
      </table>
        </div>

        {icomMonth && 
            <div className="contTable2">
            <h3 style={{margin:"0", marginBottom:"10px"}}>Доходы за {new Date(mon.date).toLocaleString('default', { month: 'long' })}</h3>
            <table className='tableCount2'>
            <thead>
                <tr style={{backgroundColor:"#e88989"}}>
                    <th>#</th>
                    <th>Дата</th>
                    <th>Сумма</th>
                    <th>Байк</th>
                </tr>
            </thead>
            <tbody>
            {icomMonth.map((item, ind) => (
                <tr key={ind}>
                     <th>{icomMonth.length - ind}</th>
                    <td>{(moment(item.addBooking.dateStart).format("DD-MM-YYYY HH:mm"))}</td>
                    <td onClick={()=>console.log(props.allBike.filter(it => it.id === item.addBooking.id)[0] )}>{item.addBooking.totalPrice}</td>
                    <td onClick={ ()=> navigate(`/redadd${(props.allBike.filter(it => it.id === item.addBooking.id))[0].addBike.id}`)} style={{cursor:"pointer"}} >
                    {
                    props.allBike &&
                    (props.allBike.filter(it => it.id === item.addBooking.id)[0] ?
                    `${(props.allBike.filter(it => it.id === item.addBooking.id))[0].addBike.model} ${(props.allBike.filter(it => it.id === item.addBooking.id))[0].addBike.color} (${(props.allBike.filter(it => it.id === item.addBooking.id))[0].addBike.regNumber})`:
                    "-")
                    }

                    </td>
                </tr>
            ))}
             </tbody>
             </table>
            </div>
        }
        </div>
    )
}
export default AllIcoms