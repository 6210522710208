import '../css/addPicture.css'
import Modal from './Modal';
import { useState, useRef, useEffect } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {getFirestore, updateDoc, doc} from 'firebase/firestore'; 
import firebaseConfig from './firebaseConfig';



const AddPictures = props => {

  //Объект который будем менять с помощью инпутов. По умолчанию подтягиваем поля из json
  const [formItems, setFormItems] = useState(null)
  const [visModal, setVisModal] = useState(false)
  const [isKok, setIsKok] = useState(false)

  useEffect(() => {
    if (props) {
      setFormItems(props.data)
      setIsKok(props.isKok)
    }
  },[props])

  //Изменяем кнопку input
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
    // console.log(formItems)

  };

  //Для уникальности изображени
  const uniquImg = String(Date.now()).slice(-6);

  //Подключаемся к базе данных
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);
  const db = getFirestore(app);

  //Загрузка изображений
  const handleImageUpload = async (event) => {

    const files = event.target.files;

    try {
    // Создаем ссылки на места загрузки файлов в Firebase Storage
    const storageRefs = [];
    const getImageUrls = [];
    
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(storage, `images/${file.name+uniquImg}`);
      storageRefs.push(storageRef);
    
      // Загружаем файлы в Firebase Storage
      await uploadBytes(storageRef, file);
    
      const imageUrl = await getDownloadURL(storageRef);
      getImageUrls.push(imageUrl);
    }
    if (formItems.addBike.imageUrl) {
    setFormItems((prevData) => ({
      ...prevData,
      addBike: {
        ...prevData.addBike,
        imageUrl: [...prevData.addBike.imageUrl, ...getImageUrls]
      }
    }))} else {
      setFormItems((prevData) => ({
        ...prevData,
        addBike: {
          ...prevData.addBike,
          imageUrl: getImageUrls
        }
      }))
    }
    // updateDocument()
    } catch (error) {
    console.error('Ошибка при загрузке изображений:', error);
    }
    };
    
    //Удаление изображения
    const removeImg = (ind) => {
      setFormItems((prevState) => ({
        ...prevState,
        addBike: {
          ...prevState.addBike,
          imageUrl: prevState.addBike.imageUrl.filter((_, index) => index !== ind)
      }}));
    }

        //Запись обновленного объявления в базу данных
        const updateDocument = async () => {
          try {
            const documentRef = doc(db, props.user.bikes, formItems.id);
            await updateDoc(documentRef, formItems);
            setVisModal(true)
            setTimeout(()=>{setVisModal(false)}, 1500)
          } catch (error) {
            // console.log(formItems)
            console.error('Ошибка при обновлении документа:', error);
            return false;
          }
      }

      const handleDownloadClick = (getUrl) => {
        // Создаем временную ссылку для скачивания
        // const downloadLink = document.createElement('a');
        // downloadLink.href = getUrl;
        // downloadLink.download = 'downloaded_image.jpg'; // имя файла для скачивания
        // downloadLink.click();
        window.open(getUrl, '_blank');
      };

    return (
      <div>
        <button style={{}} className="btnImgSave" onClick={updateDocument}>Save bike information</button>
        {visModal && <Modal/> }
       {!isKok && <button className="redAddButton" onClick={handleButtonClick} >Добавить файл</button>}
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
       {formItems &&  formItems.addBike.imageUrl ? formItems.addBike.imageUrl.map((item, id)=>
       <div key={id} style={{width:"48%"}}>
         <img  src={item} alt="?" className="img" onClick={()=>handleDownloadClick(item)} />
         {!isKok && <button onClick={()=>removeImg(id)} className="btnImg" >Удалить изобржение</button>}
       </div>
       )
       : <div></div>}
       </div>
           <input multiple style={{ display: 'none' }} ref={fileInputRef} type="file" accept=".jpg, .jpeg, .png, .HEIF" onChange={handleImageUpload} placeholder='Файлы' />
        
      </div>
    )
}

export default AddPictures