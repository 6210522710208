import '../css/AllCount.css'
import AddPrice from './AddPrice'
import RedPrice from './RedPrice'
import { useEffect, useState } from 'react'

// const moment = require('moment');

const Price = props => {

    const [allReserv, setAllReserv] = useState(null)

    const [thisReserv, setThisReserv] = useState(null)
// eslint-disable-next-line
    const [isKok, setIsKok] = useState(props.isKok)

    const [forMes, setForMes] = useState({})

    const [colorBtn, setColorBtn] = useState(false)
    

    useEffect(()=>{
      const reservForMes1 = allReserv ? allReserv
      .map(reserv => ({
        ...reserv, // создаем копию объекта
        addReservation: {
          ...reserv.addReservation, // создаем копию addReservation
          ...(reserv.addReservation.dateEnd ? { 
            days: Math.ceil((new Date(reserv.addReservation.dateEnd) - new Date(reserv.addReservation.dateStart)) / (1000 * 60 * 60 * 24))
          } : {}) // добавляем days, если dateEnd существует
        }
      })) : [];
    
      const reservForMes = reservForMes1 ? reservForMes1
      .filter(reserv => reserv.addReservation && reserv.addReservation.days)
      .map(reserv => ({
        ...reserv, // создаем копию объекта
        addReservation: {
          ...reserv.addReservation, // создаем копию allReservation
          price: Math.round((countPrice(reserv.addReservation.days, reserv.addReservation.priceday,reserv.addReservation.priceweek, reserv.addReservation.pricetwoweek, reserv.addReservation.pricemonth))/100)*100
        }
      })) : [];
      console.log("Отфильтрованные",reservForMes)
      setForMes(reservForMes)
    },[allReserv])

    // const [dateStart, setDateStart] = useState("2024-01-01")
    // const [dareEnd, setDateEnd] = useState("0")


    const copyMes = () => {
      const text = `Стоимость аренды на ${forMes[0].addReservation.days} дн:\n` + 
      forMes.map(item => `${item.addReservation.model} - ${item.addReservation.price} бат`)
      .join('\n');
      navigator.clipboard.writeText(text).then(() => {
        console.log('Скопировано в буфер обмена:', text);
        setColorBtn(true)
        setTimeout(()=>{
          setColorBtn(false)
        },1500)
      }).catch(err => {
        console.error('Ошибка при копировании:', err);
      });
    };

    const handleChangeStart = (e, id) => {
        const selectedValue = e.target.value;
        
        const updatedAllReserv = allReserv.map((group, groupId) => {
          if (groupId === id) {
            return {
              ...group,
              addReservation: {
                ...group.addReservation,
                dateStart: selectedValue,
              },
            //   daysCount: calculateDaysCount(selectedValue, group.addReservation.dateEnd),
            };
          }
          return group;
        });
    
        setAllReserv(updatedAllReserv);
      };
    
      const handleChangeEnd = (e, id) => {
        const selectedValue = e.target.value;
        
        const updatedAllReserv = allReserv.map((group, groupId) => {
          if (groupId === id) {
            return {
              ...group,
              addReservation: {
                ...group.addReservation,
                dateEnd: selectedValue,
          
              },
            //   daysCount: calculateDaysCount(group.addReservation.dateStart, selectedValue),
            };
          }
          return group;
        });
    
        setAllReserv(updatedAllReserv);
      };

      const handleChangeDays = (e, id) => {
        const selectedValue = e.target.value;
        
        const updatedAllReserv = allReserv.map((group, groupId) => {
          if (groupId === id) {
            return {
              ...group,
              addReservation: {
                ...group.addReservation,
                days: selectedValue,
          
              },
            //   daysCount: calculateDaysCount(group.addReservation.dateStart, selectedValue),
            };
          }
          return group;
        });
    
        setAllReserv(updatedAllReserv);
        console.log(updatedAllReserv)
      };
    
    const showReserv = reserv => {
        setThisReserv(reserv)
    }
    useEffect(()=>{
    if (props.price) {
      const sortedUpSort = [...props.price].sort((a, b) => {
        const priceA = parseInt(a.addReservation.priceday);
        const priceB = parseInt(b.addReservation.priceday);
        return priceA - priceB;
      });
    setAllReserv(sortedUpSort)
}
    },[props])

    const countPrice = (days, priceDay,piceWeek, piceTwooWeek, priceMonth) => {
        let price = 0
        if (days<7) {
            price=days*priceDay
        }         
        else if (days>=7 && days<12) {
          price=parseInt(days*(piceWeek/7))
      } 
        else if (days >=12 && days <25) {
            price = parseInt(days*(piceTwooWeek/14))
        } else if (days >=25) {
            price = parseInt(days*(priceMonth/30))
        }

        return price
    }

    return (
        <div>
          <div style={{width:"87%", display:"flex", flexDirection:"row", justifyContent:"space-between", minWidth:"900px", margin:"auto"}}>
        <h2 style={{margin:"0", marginBottom:"0px", marginTop:"10px"}}>Цены на байки</h2>
        <div className="btnAllRes" onClick={copyMes} style={{backgroundColor: colorBtn ? "#8090cf":""}}>Скопировать</div>
        </div>
        <div className="contTable1" style={{width:"95%", marginTop:"20px", minWidth:"1010px"}}>
        <table className='tableCount' style={{tableLayout:"fixed", width:"100%"}}>
        <colgroup>
          <col style={{ width: "160px" }}/> {/* Первый столбец */}
          <col /> {/* Второй столбец */}
          <col /> {/* Третий столбец */}
          <col /> {/* Четвертый столбец фиксированной ширины */}
          <col /> {/* Пятый столбец */}
          <col /> {/* Шестой столбец */}
          <col style={{ width: "160px" }} /> {/* Седьмой столбец */}
          <col style={{ width: "160px" }}/> {/* Восьмой столбец */}
          <col /> {/* Девятый столбец */}
          <col /> {/* Десятый столбец */}
        </colgroup>
        <thead >
          <tr>
              <th>Model</th> 
              <th>Price day</th> 
              <th>Price week</th> 
              <th>Price 2 week</th> 
              <th>Price month</th> 
              <th>Deposit</th>            
              <th>Date start</th> 
              <th>Date finish</th> 
              <th>Days rent</th> 
              <th>Price</th> 

          </tr>
        </thead>
        <tbody>
        {allReserv && allReserv.map((group, id) => (
            <tr key={id} >
            <td onClick={()=> isKok? "": showReserv(group)} style={{cursor:"pointer"}}>{group.addReservation.model}</td>
            <td onClick={()=> isKok? "": showReserv(group)} style={{cursor:"pointer"}}>{group.addReservation.priceday}</td>
            <td onClick={()=> isKok? "": showReserv(group)} style={{cursor:"pointer"}}>{group.addReservation.priceweek}</td>
            <td onClick={()=> isKok? "": showReserv(group)} style={{cursor:"pointer"}}>{group.addReservation.pricetwoweek}</td>
            <td onClick={()=> isKok? "": showReserv(group)} style={{cursor:"pointer"}}>{group.addReservation.pricemonth}</td>
           {group.addReservation.deposit ? <td onClick={()=>isKok? "":  showReserv(group)} style={{cursor:"pointer"}}>{group.addReservation.deposit}</td>:<td onClick={()=>isKok? "":  showReserv(group)} style={{cursor:"pointer"}}>-</td>}
            <td style={{backgroundColor: group.addReservation.dateStart ? "#badec0": "white"}}>
                <input 
                style={{backgroundColor: group.addReservation.dateStart ? "#badec0": "white"}}
                className="inpPrice"
                type="date"
                onChange={(e) => handleChangeStart(e, id)}
                // value={group.addReservation.dateStart ? group.addReservation.dateStart : '2024-01-01'}
                />
            </td>
            <td style={{backgroundColor: group.addReservation.dateEnd ? "#badec0": "white"}}>
                <input 
                style={{backgroundColor: group.addReservation.dateEnd ? "#badec0": "white"}}
                className="inpPrice"
                type="date"
                onChange={(e) => handleChangeEnd(e, id)}
                // value={group.addReservation.dateEnd ? group.addReservation.dateEnd: "2024-01-01"}
                />
            </td>
            {group.addReservation.dateEnd ?
            <td style={{backgroundColor:group.addReservation.dateEnd && group.addReservation.dateStart ? "#badec0": "white"}}>{group.addReservation.dateEnd && group.addReservation.dateStart ? Math.ceil((new Date(group.addReservation.dateEnd)-new Date(group.addReservation.dateStart)) / (1000 * 60 * 60 * 24)):"-"}</td>:
            <td >
              <input
                className="inpPrice"
                onChange={(e) => handleChangeDays(e, id)}
                style={{width:"50px"}}
              />
            </td>
}
{group.addReservation.dateEnd ?
            <td onClick={()=> console.log()} style={{backgroundColor:group.addReservation.dateEnd && group.addReservation.dateStart ? "#badec0": "white"}}>{group.addReservation.dateEnd && group.addReservation.dateStart ? Math.round(countPrice(Math.ceil((new Date(group.addReservation.dateEnd)-new Date(group.addReservation.dateStart)) / (1000 * 60 * 60 * 24)), group.addReservation.priceday, group.addReservation.priceweek, group.addReservation.pricetwoweek, group.addReservation.pricemonth)/100)*100:"-"}</td>:
            <td  style={{backgroundColor:group.addReservation.days ? "#badec0": "white"}}>{group.addReservation.days ? Math.round(countPrice(group.addReservation.days, group.addReservation.priceday,group.addReservation.priceweek, group.addReservation.pricetwoweek, group.addReservation.pricemonth)/100)*100:"-"}</td>
}
            </tr>
        ))}
        </tbody>
      </table>
      </div>
            {!isKok && <RedPrice reserv={thisReserv} user={props.user}/>}
            {!isKok && <AddPrice allReservations={props.allReservations} user={props.user}/>}
        </div>
    )
}

export {Price}