
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {doc, updateDoc, getFirestore, deleteDoc} from 'firebase/firestore'; // Firebase Modular SDK импорты
import '../css/AddBike.css'
import { useEffect, useState } from 'react'
import firebaseConfig from './firebaseConfig'

const RedReserv = props => {
    //Подключаемся к FireBase
    const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    const db = getFirestore(app);

    const [redReservation, setRedReservation] = useState(null)

    useEffect(() => {
        if (props.reserv) { 
            setRedReservation(props.reserv)
        }
      }, [props]);

    //Функция изменения значения
    const handleChange = (event) => {
        const { name, value } = event.target;
        setRedReservation((prevData) => ({
          ...prevData,
          addReservation: {
            ...prevData.addReservation,
            [name]: value
          }
        }));
      };

        
  const updateDocument = async () => {
    try {
      const documentRef = doc(db, props.user.reservations, props.reserv.id);
      await updateDoc(documentRef, redReservation);
      window.location.reload()
    } catch (error) {
      console.error('Ошибка при обновлении документа:', error);
      return false;
    }
}
const deleteDocument = async () => {
  try {
    const documentRef = doc(db, 'reservations', props.reserv.id); 
    await deleteDoc(documentRef);
    window.location.reload();

  } catch (error) {
    console.error("Ошибка при удалении документа из базы данных", error);
  }
};
    return (
        <div >
            {redReservation &&
            <div className="contRedAdd" style={{backgroundColor:"#d1a7b8", paddingTop:"4%", width:"87%", minWidth:"900px", margin:"auto", boxSizing:"border-box", marginTop:"40px"}}>
                <h3 style={{margin:"0", marginBottom:"10px"}}>Редактирование бронирования</h3>
                <div className="contInput">
                     <p>Дата аренды:</p>
                    <input 
                                  name="date"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.date}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Срок аренды:</p>
                    <input 
                                  name="term"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.term}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Какой байк:</p>
                    <input 
                                  name="bike"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.bike}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Куда доставить:</p>
                    <input 
                                  name="location"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.location}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Контакт:</p>
                    <input 
                                  name="contact"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.contact}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Усовия:</p>
                    <input 
                                  name="price"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redReservation.addReservation.price}
                                  onChange={handleChange}
                        />
                    </div>
                    <div>
                        <button className="redButton" onClick={updateDocument}>Сохранить</button>
                        <button className="redButton" style={{backgroundColor:"#f09e9e", cursor:"pointer"}} onClick={deleteDocument}>Удалить бронирование</button>
                    </div>
                    
            </div>
}
        </div>
    )
}

export default RedReserv