import { useEffect, useState } from 'react';
import '../css/ListExpenses.css'
import AddExpenses from './AddExpenses';
import RedExpenses from './RedExpenses';

const ListExpenses = props => {
 
    const [allData, setAllData] = useState(null)
    const [exp, setExp] = useState(null)

    useEffect(()=> { 
      if (props.allExpenses) {
        const bikesData = props.allExpenses
        const sortedUpSort = [...bikesData].sort((b, a) => {
            const dateA = !a.addExp.date ? new Date('9999-12-31') : new Date(a.addExp.date);
            const dateB = !b.addExp.date  ? new Date('9999-12-31') : new Date(b.addExp.date);
            return dateA - dateB;
        })
        setAllData(sortedUpSort.filter(item => item.addExp.id === props.id));
      }
      // eslint-disable-next-line
  },[])
  
    const showExp = (item) => {
      setExp(item)
    }

    return (
      <div>
    <h2 style={{margin:"0", marginBottom:"20px"}}>Расходы по байку</h2>
      <table className='tableExp'>
        <thead >
          <tr >
              <th>Дата расхода</th> 
              <th>Сумма расходов</th> 
              <th>Комментарий</th>   
          </tr>
        </thead>
        <tbody>
          {allData && allData.map((item, ind)=> 
            <tr onClick={()=> showExp(item)} key={ind} style={{cursor:"pointer"}}>
                <td>{item.addExp.date}</td>
                <td>{item.addExp.amount}</td>
                <td>{item.addExp.comment}</td>
            </tr>
          )}
        </tbody>
      </table>
      {exp && 
      <RedExpenses exp={exp} item={props.item} user={props.user}/>
      }
      {allData && <AddExpenses id={props.id} user={props.user}/>
      }

      </div>
    );
  }

export default ListExpenses